<template>
  <nav class="main-nav d-flex align-items-center pr-3">
    <div
      class="main-nav__brand d-flex justify-content-center align-items-center"
    >
      <a href="">
        <img
        v-if="constantes.EMPRESA === 'veloz'"
          :src="require('../../assets/images/veloz-logo-login.png')"
          alt="Sistema de Ensino Veloz"
          class="img-fluid"
          style="height: 50px!important;"
        >
        <img
        v-if="constantes.EMPRESA === 'uniavan'"
          :src="require('../../assets/images/seu-logo-login.png')"
          alt="Seu - Sistema de Ensino Uniavan"
          class="img-fluid"
          style="height: 50px!important;"
        >
        <!-- <img
          :src="require('../../assets/images/seu-logo.png')"
          alt="Seu - Sistema de Ensino Uniavan"
          class="img-fluid"
        /> -->
      </a>
    </div>

    <button
      id="menu-toggle"
      class="navbar-toggler d-block ml-3"
      @click="toggleLeftMenu"
    >
      <span class="navbar-toggler-icon" />
    </button>

    <div class="main-nav__profile btn-group ml-auto">
      <button
        type="button"
        class="btn btn__clean dropdown-toggle fs-14 color-oct"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="toggleRightMenu"
      >
        <img
          :src="require('../../assets/images/avatar.png')"
          :alt="nomeUsuario"
          class="rounded-circle mr-3"
        />
        {{ nomeUsuario }}
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        :class="{ show: showRightMenu }"
      >
        <a
          class="dropdown-item justify-content-end"
          href="#"
          @click.prevent="profile"
          >Meu Perfil</a
        >
        <a
          class="dropdown-item justify-content-end"
          href="#"
          @click.prevent="logout"
          >Deslogar/Logout</a
        >
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { constantes } from "@/constants";
import authService from "@/modules/auth/services";

export default {
  data() {
    return {
      showRightMenu: false,
      nomeUsuario: localStorage.getItem(constantes.STORAGE_UNIAVAN_NOME_USUARIO),
      constantes: constantes
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    toggleLeftMenu() {
      this.$root.$emit("Dashboard::toggleLeftMenu");
    },
    toggleRightMenu() {
      this.showRightMenu = !this.showRightMenu;
    },
    logout() {
      authService.logout();
      document.location.reload();
    },
    profile() {
      this.$router.push({
        name: "perfil",
      });
    },
    home() {
      this.$router.push({
        name: "",
      });
    },
  },
};
</script>

<style lang="scss">
</style>
