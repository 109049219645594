<template>
  <div>
    <main>
      <!-- <span class="badge badge-rascunho">{{ status.descricao }} </span> -->
      <span
        v-if="status.codigo == cor_status.STATUS_RASCUNHO"
        class="badge-rascunho"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_EM_REVISAO"
        class="badge-revisao"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_REPROVADO"
        class="badge-reprovado"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_APROVADO"
        class="badge-aprovado"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_DISPONIBILIZADO"
        class="badge-disponibilizado"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_EM_ANDAMENTO"
        class="badge-andamento"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_CONCLUIDO"
        class="badge-concluido"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_EM_CORRECAO"
        class="badge-warning"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_CORRIGIDO"
        class="badge-info"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_LIBERADO"
        class="badge-success"
        >{{ status.descricao }}
      </span>
      <span
        v-if="status.codigo == cor_status.STATUS_AGENDADO"
        class="badge-agendado"
        >{{ status.descricao }}
      </span>
    </main>
  </div>
</template>

<script>
import { constantes } from "@/constants";

export default {
  props: {
    status: {
      type: Object,
    },
  },
  data() {
    return {
      cor_status: constantes,
    };
  },
};
</script>
