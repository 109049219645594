<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Correção de Provas
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Provas
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Correção
                </li>
              </ol>
            </nav>
          </div>
          <div class="page-content" v-if="isProva()">
            {{ prova.disciplina.text }} - {{ prova.turma.titulo }} |
            {{ prova.turma.anoEnsino.titulo }}
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-4">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          type="search"
                          v-model="searchQuery"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <select
                    id="disciplina"
                    class="form-control"
                    @change="onChangeStatus($event)"
                  >
                    <option
                      v-for="item in status_correcao"
                      :key="item.value"
                      :value="item.value"
                      :text="item.text"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <button
                        class="btn btn-classic"
                        @click="limparFiltro()"
                      >
                        Limpar
                      </button>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-classic"
                        @click="liberarGabarito()"
                      >
                        Liberar Gabarito
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                id="provasCorrecao"
                class="block__classic table table__correction table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="align-left">Aluno</th>
                    <th>Nota</th>
                    <th>Questões corrigidas</th>
                    <th>Status</th>
                    <th class="no-sort">Ação</th>
                    <th class="no-sort">Data da finalização</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in resultQuery" :key="item.id">
                    <td class="text-left pl-4" v-html="item.aluno.nome"></td>
                    <td class="text-center pl-4 color-proj-primary">
                      <strong>{{ item.notaAlcancada | numberFilter }}</strong>
                    </td>
                    <td class="text-center pl-4">
                      {{ item.aluno.notaAlcancada }}
                    </td>
                    <td class="text-center pl-4">
                      <ProvaStatus :status="item.status" />
                    </td>
                    <td>
                      <a
                        v-if="podeCorrigirProva(item)"
                        href="#"
                        @click.prevent="corrigirProva(item.prova.id, item.aluno.id)"
                        class="link"
                      >
                        <img
                          src="@/assets/images/check.svg"
                          class="mr-2"
                        />Corrigir</a
                      >
                    </td>

                    <td class="text-center pl-4 ">
                      {{
                        item.finalizadaEm | formatDate
                      }}&nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Router from "vue-router";
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import Swal from "sweetalert2";
import moment from "moment";
import { constantes } from "@/constants";
import ProvaStatus from "@/components/provas/ProvaStatus";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    ProvaStatus,
  },
  data() {
    return {
      showLeftMenu: true,
      hasFilter: this.$route.params.filter ? this.$route.params.filter : false,
      provas_alunos: [],
      prova: null,
      alunos: [],
      searchQuery: "",
      status_correcao: [
        { text: "Filtrar por (Status da Correção)", value: '' },
        { text: "Em Correcao", value: "EmCorrecao" },
        { text: "Corrigido", value: "Corrigido" },
      ],
      questao_corrigidas: [{ id: 3 }, { id: 3 }],
      cor_status: constantes,
    };
  },
  filters: {
    qtdCorrigida(id) {
      this.provas_alunos.forEach((element) => {
        console.log(element.id);
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY H:mm");
      } else {
        return "-";
      }
    },
    numberFilter(value) {
      return Number(value).toFixed(1);
    },
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.provas_alunos.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.aluno.nome.toLowerCase().includes(v));
        });
      } else {
        return this.provas_alunos;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    isProva() {
      return this.prova != null ? true : false;
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    loadData() {
      this.$api
        .get("/provas-alunos/" + this.$route.params.id)
        .then((response) => {
          this.provas_alunos = response.data;
          this.prova = response.data[0].prova;
          this.$root.$emit("Spinner::hide");

          this.provas_alunos.map(function(entry) {
            entry.finalizadaEm = entry.finalizadaEm
              .substring(0, 19)
              .replace("T", " ");
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onChangeStatus(e) {
      // this.provas_alunos.forEach((res) => {
      //   this.provas_alunos = []
      //   console.log(res)
      //   if(res.status.descricao == e.target.value){
      //     this.provas_alunos.push(res)
      //   }
      // })
      this.$api
        .get("/provas-alunos/" + this.$route.params.id)
        .then((response) => {
          this.provas_alunos = response.data;
          if(e.target.value){
            this.provas_alunos = this.provas_alunos.filter(res => res.status.descricao == e.target.value);
          }
          this.$root.$emit("Spinner::hide");

          this.provas_alunos = this.provas_alunos.map(function(entry) {
            entry.finalizadaEm = entry.finalizadaEm
              .substring(0, 19)
              .replace("T", " ");
              return entry;
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    limparFiltro(){
      location.reload();
    },
    liberarGabarito() {
      this.$api
        .post("provas-fluxo-status/" + this.prova.id, { status: "Liberado" })
        .then((response) => {
          this.$root.$emit("Spinner::hide");
          //this.provas.splice(index, 1);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Gabarito liberado!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    corrigirProva(prova, aluno) {
      console.log(prova);
      this.$router.push({
        name: "provas_aluno_corrigir",
        params: { prova: prova, aluno: aluno },
      });
    },
    podeCorrigirProva(provaAluno){
      return !["Corrigido", "Liberado"].includes(provaAluno.status.codigo);
    }
  },
};
</script>

<style></style>
